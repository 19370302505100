<app-exam-manager class="mt-1 mb-1" #examManager [exam]="exam" (examChange)="onExamChanged($event)"></app-exam-manager>
<mat-tab-group lang="en-US">
  <mat-tab label="Examen">
    <div class="p-1">
      <app-exam-definition [exam]="exam"></app-exam-definition>
    </div>
  </mat-tab>
  <mat-tab label="Resultaten ({{exam.results.length}})">
    <div class="pt-1 pb-1">
      <app-exam-results #examResults [exam]="exam"></app-exam-results>
    </div>
  </mat-tab>
</mat-tab-group>

<mat-spinner diameter="30" class="spinner" *ngIf="loading"></mat-spinner>

<div style="height: 20px;"></div>
<div>
  <!-- <button  mat-stroked-button color="primary" (click)="showJson = !showJson">Toggle Json</button> -->
  <pre *ngIf="showJson">{{exam | json}}</pre>
</div>

<div class="app-footer">
  <div class="app-name" title="v{{appVersion}}">Examenpuntjes</div>
</div>