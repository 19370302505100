<div class="mb-2">
  <span>Invoer</span>
  <mat-slide-toggle [(ngModel)]="isModeSummary" style="margin-left: 10px; margin-right: 10px;"></mat-slide-toggle>
  <span>Overzicht</span>
</div>

<table mat-table #resultsTable [dataSource]="dataSource" class="mat-elevation-z1" style="display: block;width: 100%; height: 70vh; overflow: auto">
  <ng-container matColumnDef="buttons" sticky>
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-stroked-button style="min-width:40px;margin-right: 10px;" (click)="removeResult(element)" color="warn" title="Resultaat verwijderen">-</button>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <button mat-stroked-button style="min-width:40px;margin-right: 10px;" (click)="addResult()" title="Resultaat toevoegen">+</button>
    </td>
  </ng-container>

  <ng-container matColumnDef="studentName" sticky>
    <th mat-header-cell *matHeaderCellDef style="text-align:left;min-width: 180px;">Leerling</th>
    <td mat-cell *matCellDef="let element" style="min-width:180px;">
      <mat-form-field *ngIf="!isModeSummary" style="padding-top: 6px;">
        <input matInput type="text" [(ngModel)]="element.studentName" onfocus="this.select()" />
      </mat-form-field>
      <div *ngIf="isModeSummary">{{element.studentName}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="pt-1 pb-1" style="text-align:right">
      <div>Gemiddeld:</div>
      <div *ngIf="!isModeSummary" class="mt-1">Totaal onbeantwoord:</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="info">
    <th mat-header-cell *matHeaderCellDef>
      <div style="padding-right: 10px;text-align: right">
        <span *ngIf="!isModeSummary">Vraag</span>
        <span *ngIf="isModeSummary">Opgave</span>
      </div>
      <div style="padding-right: 10px;text-align: right">Max.</div>
    </th>
    <td mat-cell *matCellDef="let element"></td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container *ngFor="let assignment of exam.assignments; let $aIndex = index; let $isLastAssignment = last">
    <ng-container matColumnDef="{{question.id}}" *ngFor="let question of assignment.questions; let $isLastQuestion = last">
      <th mat-header-cell [class.grey]="$aIndex % 2 !== 0" *matHeaderCellDef style="text-align: center; min-width: 34px;">
        <div class="bold">{{question.questionNumber}}</div>
        <div style="font-size: smaller">{{question.maxPoints}}</div>
      </th>
      <td mat-cell *matCellDef="let element; let $resultIndex = index; let $isLastResult = last" [class.grey]="$aIndex % 2 !== 0" style="max-width:24px; padding-top: 6px;">
        <input id="{{'answer_' + $resultIndex + '_' + question.questionNumber}}" class="mat-input-element custom-input" type="text" [min]="0" [max]="question.maxPoints" 
        appEmptyScoreInput [ngModel]="getResult(element,question.id)" (ngModelChange)="setResult(element, question, $event)" style="text-align: center" onfocus="this.select()" 
        (keypress)="keyPress($resultIndex, question.questionNumber, $isLastResult, $isLastAssignment, $isLastQuestion)" [class.empty-score]="getResult(element,question.id) === 'n'" />
      </td>
      <td mat-footer-cell *matFooterCellDef class="pt-1 pb-1" style="text-align:center">
        <div class="mb-1">{{ getQuestionAverage(question.id) | number:'1.1-1' | grade}}</div>
        <!-- Highlight if percentage of empty results is greater or equal than 25% -->
        <div [style.color]="getQuestionEmptyTotal(question.id) >= (exam.results.length / 4) ? 'orangered' : 'inherit'">{{ getQuestionEmptyTotal(question.id)}}</div>
      </td>
    </ng-container>
  </ng-container>

  <ng-container matColumnDef="{{assignment.id}}" *ngFor="let assignment of exam.assignments; let $aIndex = index;">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;min-width:35px;" [class.grey]="$aIndex % 2 !== 0">
      <div class="bold" style="margin-bottom: 2px;">{{$aIndex + 1}}</div>
      <div style="font-size: smaller">{{assignment.totalPoints}}</div>
    </th>
    <td mat-cell *matCellDef="let element" style="width:35px; text-align: center" [class.grey]="$aIndex % 2 !== 0">
      <span>{{getAssignmentTotal(element.questionResults, assignment.id)}}</span>
    </td>
    <td mat-footer-cell *matFooterCellDef style="text-align:center">{{ getAssignmentAverage(assignment.id) | number:'1.1-1' | grade}}
    </td>
  </ng-container>

  <ng-container matColumnDef="fill">
    <th mat-header-cell *matHeaderCellDef style="text-align: center">
    </th>
    <td mat-cell *matCellDef="let element" style="width:75%; text-align: center">
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
    </td>
  </ng-container>

  <ng-container matColumnDef="totalPoints">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">
      <div>Totaal</div>
      <div style="font-size: smaller">{{exam.totalPoints}}</div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column">
        <span>{{element.totalPoints}}</span>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
      {{getTotalPointsAverage() | number: '1.1-1' | grade}}
    </td>
  </ng-container>

  <ng-container matColumnDef="cse">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">CSE</th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column">
          <app-result-indicator [value]="element.calculateCSE(exam.totalPoints, exam.nTerm) | number :'1.1-1'" delimiter="," [negative]="5.5" [positive]="6.5"></app-result-indicator>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
      {{getCSEAverage(exam.nTerm) | number:'1.1-1' | grade }}
    </td>
  </ng-container>

  <ng-container matColumnDef="se">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">SE</th>
    <td mat-cell *matCellDef="let element" class="calculated-column" style="width: 80px;min-width: 80px;padding-top: 6px;text-align:right">
      <mat-form-field style="display:inline-block;max-width: 30px;">
        <input matInput step="0.1" min="0" max="10" type="number" (ngModelChange)="element.se=$event" [ngModel]="element.se | number:'1.1-1'" style="text-align: right; width:30px" 
        [class.negative]="element.se < 5.5"/>
      </mat-form-field>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
      {{getSEAverage() | number:'1.1-1' | grade }}
    </td>
  </ng-container>

  <ng-container matColumnDef="finalGrade">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">eind</th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column bold">
        <app-result-indicator [value]="element.calculateFinalGrade(exam.totalPoints, exam.nTerm) | number : '1.2-2'" delimiter="," [negative]="5.5" [positive]="6.5"></app-result-indicator>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
      {{getFinalGradeAverage(exam.nTerm) | number:'1.2-2' | grade}}
    </td>
  </ng-container>

  <ng-container matColumnDef="finalGradeRounded">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">eind</th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column bold">
        <app-result-indicator [value]="element.calculateFinalGrade(exam.totalPoints, exam.nTerm) | number : '1.0-0'" [negative]="5.5" [positive]="6.5"></app-result-indicator>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">

    </td>
  </ng-container>

  <ng-container matColumnDef="diff">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">
      CSE-SE
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column">
        <span *ngIf="element.se">{{(element.calculateCSE(exam.totalPoints, exam.nTerm) - element.se) | number : '1.1-1' | grade}}</span>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
    </td>
  </ng-container>

  <ng-container matColumnDef="tolerance">
    <th mat-header-cell *matHeaderCellDef class="calculated-column">
      Speling
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="calculated-column">
        <span *ngIf="element.se" [class.negative]="element.calculateTolerance(exam.totalPoints, exam.nTerm) > -4 && element.calculateTolerance(exam.totalPoints, exam.nTerm) < 4">
        {{element.calculateTolerance(exam.totalPoints, exam.nTerm) | number: '1.0-0'}}</span>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef class="calculated-column">
    </td>
  </ng-container>

  <ng-container matColumnDef="customNTermCSE">
    <th mat-header-cell *matHeaderCellDef class="grey" style="min-width:110px" colspan="2">
      <div style="text-align: center">CSE / eind</div>
      <div style="text-align: center">bij N-term</div>
      <div style="text-align: center">
        <button mat-icon-button class="mat-icon-button-sm" (click)="changeCustomNTerm(1/10*-1)">-</button>
        <span>{{customNTerm | number | grade}}</span>
        <button mat-icon-button class="mat-icon-button-sm" (click)="changeCustomNTerm(1/10)">+</button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element" class="grey">
      <div class="calculated-column bold" style="width:auto;text-align:right">
        <app-result-indicator [value]="element.calculateCSE(exam.totalPoints, customNTerm) | number : '1.1-1'" delimiter="," [negative]="5.5" [positive]="6.5"></app-result-indicator>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="calculated-column">{{getCSEAverage(customNTerm) | number:'1.1-1' | grade }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="customNTermFinal">
    <th mat-header-cell *matHeaderCellDef class="grey" style="display: none;"></th>
    <td mat-cell *matCellDef="let element" class="grey">
      <div class="calculated-column bold" style="width:auto;text-align:center">
        <app-result-indicator [value]="element.calculateFinalGrade(exam.totalPoints, customNTerm) | number : '1.2-2'" delimiter="," [negative]="5.5" [positive]="6.5"></app-result-indicator>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="calculated-column" style="text-align: center;">{{getFinalGradeAverage(customNTerm) | number:'1.2-2' | grade}}</div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns; sticky : true"></tr>
  <tr mat-row *matRowDef="let row; columns: columns"></tr>
  <ng-container *ngIf="examResults.length > 0">
    <tr mat-footer-row *matFooterRowDef="columns; sticky : true" class="grey bold"></tr>
  </ng-container>

</table>