import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { GradePipe } from './grade.pipe';
import { ExamDefinitionComponent } from './exam-definition/exam-definition.component';
import { ExamResultsComponent } from './exam-results/exam-results.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { ExamManagerComponent } from './exam-manager/exam-manager.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ResultIndicatorComponent } from './result-indicator.component';
import { EmptyScoreInputDirective } from './empty-score-input.directive';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    MatDividerModule,
    MatTableModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatIconModule,
    MatProgressSpinnerModule
    // NoopAnimationsModule
  ],
  exports: [GradePipe],
  declarations: [
    AppComponent,
    ExamDefinitionComponent,
    ExamResultsComponent,
    ExamManagerComponent,
    GradePipe,
    ResultIndicatorComponent,
    // CustomMaxDirective,
    EmptyScoreInputDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
