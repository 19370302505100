<div class="mb-2">
  <div>
    <label class="input-label">Examen</label>
    <mat-form-field appearance="standard" style="min-width: 350px">
      <input matInput placeholder="Naam" [(ngModel)]="exam.name">
    </mat-form-field>
  </div>
  <div>
    <label class="input-label">N-term</label>
    <mat-form-field appearance="standard">
      <input matInput step="0.1" min="0" max="2" type="number" (ngModelChange)="exam.nTerm=$event"
            [ngModelOptions]="{updateOn: 'blur'}"
            [ngModel]="exam.nTerm | number:'1.1-1'" />
    </mat-form-field>
  </div>
</div>
<div class="mb-2">
  <div class="mb-1">
    <div *ngFor="let a of exam.assignments;let $aIndex = index; let $aLast = last"
      style="display: flex; justify-content: space-between; align-items: center" [class.mb-1]="!$aLast">
      <div>
        <div class="bold" style="display: inline-block; margin-bottom:-11px; margin-right: 50px;">Opgave
          {{$aIndex
          + 1}}</div>
        <div style="display: inline-block;" *ngFor="let q of a.questions;let $qIndex = index;let  $qLast= last;">
          <div style="width:40px; display: inline-block; text-align: center">
            <div class="bold">{{q.questionNumber}}</div>
            <mat-form-field style="width:inherit;">
              <input matInput type="number" min="0" name="q_{{$qIndex}}" [(ngModel)]="q.maxPoints" style="text-align: center;" onfocus="this.select()" />
            </mat-form-field>
          </div>
          <span *ngIf="!$qLast" style="display: inline-block; width:10px;"></span>
        </div>
      </div>
      <div>
        <span class="bold" style="margin-right: 10px;">Vraag</span>
        <button mat-stroked-button style="min-width:40px;margin-right: 10px;" (click)="addQuestion(a)">+</button>
        <button mat-stroked-button style="min-width:40px;margin-right: 10px;" (click)="removeLastQuestion(a)" [disabled]="a.questions.length <=1">-</button>
        <span style="display:inline-block;width: 75px; text-align:right;">[{{a.totalPoints}} pnt.]</span>
      </div>
    </div>
  </div>

  <div style="display: flex; justify-content: space-between; align-items: center">
    <div>
      <span class="bold" style="margin-right: 10px;">Opgave</span>
      <button mat-stroked-button style="min-width:40px;margin-right: 10px;" (click)="addAssignment()">+</button>
      <button mat-stroked-button style="min-width: 40px;" (click)="removeLastAssignment()" [disabled]="exam.assignments.length <=1" color="warn">-</button>
    </div>
    <div>
      <span class="bold" style="text-align: right;">[Totaal {{exam.totalPoints}} pnt.]</span>
    </div>
  </div>
</div>