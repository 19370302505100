import { Exam } from './exam';
import { ExamAssignment } from './exam-assignment';
import { ExamQuestion } from './exam-question';
import { ExamResult } from './exam-result';

// tslint:disable: max-line-length
export class ExamGenerator {

  static generateNewExam(): Exam {
    let exam = new Exam();
    exam.name = '';
    exam.nTerm = 1.0;

    this.generateAssignment(exam, 2, 2, 2, 2, 2);

    exam.recalculateQuestionNumbers();

    exam.results.push(new ExamResult());

    return exam;
  }

  static generateExistingExam(): Exam {
    let exam = new Exam();
    exam.name = 'HAVO cohort 17-19 BIOLOGIE';
    exam.nTerm = 0.7;

    this.generateAssignment(exam, 1, 2, 2, 2, 2);
    this.generateAssignment(exam, 2, 2, 2, 1, 2);
    this.generateAssignment(exam, 2, 2, 2, 2);
    this.generateAssignment(exam, 2, 2);
    this.generateAssignment(exam, 2, 2, 2, 2, 2, 2, 2);
    this.generateAssignment(exam, 2, 2, 2, 2, 1, 2, 2, 1);
    this.generateAssignment(exam, 1, 1, 1, 1, 2);
    this.generateAssignment(exam, 1, 2, 2, 2, 2, 2, 2);

    exam.recalculateQuestionNumbers();

    this.generateResult(exam, 'Leerling 1', 6.5 , 1, 2, 1, 0, 2, 2, 2, 1, 0, 0, 1, 2, 0, 2, 0, 2, 1, 2, 0, 2, 2, 2, 2, 0, 0, 0, 2, 1, 2, 2, 1, 1, 1, 1, 1, 2, 1, 2, 0, 2, 2, 0, 2);
    this.generateResult(exam, 'Leerling 2', 6.5 , 0, 2, 1, 0, 2, 0, 2, 1, 0, 1, 1, 2, 2, 2, 0, 2, 1, 0, 2, 2, 0, 2, 2, 2, 1, 1, 2, 0, 0, 2, 0, 1, 1, 0, 1, 2, 1, 0, 1, 0, 2, 0, 2);
    this.generateResult(exam, 'Leerling 3', 6.9 , 0, 2, 0, 0, 2, 0, 0, 2, 1, 0, 2, 2, 2, 2, 1, 0, 1, 2, 0, 1, 2, 0, 2, 0, 0, 0, 0, 0, 2, 2, 1, 0, 1, 1, 1, 2, 0, 2, 2, 2, 2, 0, 2);
    this.generateResult(exam, 'Leerling 4', 8.3 , 1, 0, 2, 0, 2, 2, 2, 2, 1, 2, 2, 2, 2, 2, 1, 2, 0, 2, 2, 2, 0, 1, 2, 2, 2, 2, 2, 1, 2, 2, 1, 1, 1, 1, 1, 2, 1, 2, 2, 2, 2, 2, 2);
    this.generateResult(exam, 'Leerling 5', 7.0 , 1, 2, 2, 2, 2, 2, 2, 2, 1, 1, 1, 2, 0, 2, 1, 2, 2, 2, 2, 1, 0, 2, 2, 2, 0, 2, 2, 1, 2, 2, 0, 1, 0, 0, 0, 2, 0, 0, 0, 2, 2, 2, 2);
    this.generateResult(exam, 'Leerling 6', 6.7 , 1, 2, 1, 2, 2, 0, 2, 1, 0, 2, 2, 2, 2, 2, 2, 2, 1, 2, 2, 1, 2, 2, 0, 2, 1, 2, 2, 0, 1, 2, 1, 1, 0, 0, 1, 2, 0, 0, 1, 0, 2, 0, 2);
    this.generateResult(exam, 'Leerling 7', 6.6 , 1, 2, 1, 2, 2, 1, 1, 1, 1, 2, 0, 2, 2, 2, 0, 2, 1, 2, 2, 2, 0, 2, 2, 0, 0, 0, 2, 0, 2, 2, 0, 0, 1, 0, 1, 2, 0, 0, 2, 2, 0, 2, 2);
    this.generateResult(exam, 'Leerling 8', 7.3 , 1, 2, 2, 0, 2, 1, 1, 2, 1, 1, 0, 2, 0, 1, 1, 2, 1, 2, 0, 1, 0, 1, 0, 2, 1, 1, 0, 0, 2, 2, 1, 1, 0, 0, 1, 2, 1, 2, 1, 0, 2, 2, 2);
    this.generateResult(exam, 'Leerling 9', 7.9 , 1, 2, 0, 2, 2, 1, 0, 2, 0, 2, 2, 0, 2, 0, 1, 0, 0, 2, 2, 2, 0, 1, 2, 0, 0, 1, 2, 0, 1, 2, 0, 1, 0, 0, 0, 2, 0, 2, 1, 0, 0, 0, 2);
    this.generateResult(exam, 'Leerling 10', 5.8 , 0, 2, 0, 2, 2, 0, 0, 2, 0, 1, 1, 2, 2, 1, 0, 0, 1, 2, 0, 2, 2, 1, 2, 2, 0, 2, 2, 0, 2, 2, 1, 1, 1, 1, 1, 2, 0, 0, 2, 0, 2, 2, 0);
    this.generateResult(exam, 'Leerling 11', 7.3 , 1, 2, 2, 0, 2, 2, 0, 0, 0, 2, 0, 0, 2, 2, 1, 2, 1, 2, 2, 1, 2, 2, 0, 2, 0, 1, 2, 1, 1, 2, 0, 1, 0, 1, 1, 2, 1, 2, 2, 0, 2, 2, 2);
    this.generateResult(exam, 'Leerling 12', 7.2 , 1, 0, 0, 0, 2, 1, 2, 1, 0, 0, 1, 2, 0, 2, 2, 2, 2, 2, 0, 2, 2, 2, 0, 2, 0, 2, 2, 0, 1, 2, 1, 1, 1, 1, 1, 2, 0, 0, 1, 0, 2, 0, 2);
    this.generateResult(exam, 'Leerling 13', 5.9 , 1, 2, 0, 0, 2, 2, 0, 2, 1, 2, 2, 2, 0, 2, 2, 2, 1, 2, 2, 0, 2, 2, 0, 2, 0, 2, 2, 0, 2, 2, 0, 1, 1, 1, 1, 2, 0, 0, 2, 0, 2, 0, 0);
    this.generateResult(exam, 'Leerling 14', 5.6 , 1, 2, 1, 0, 2, 1, 1, 2, 1, 2, 1, 2, 0, 2, 1, 2, 1, 2, 2, 1, 2, 2, 0, 0, 1, 2, 2, 0, 0, 2, 0, 0, 1, 1, 1, 2, 0, 2, 0, 2, 0, 0, 2);
    this.generateResult(exam, 'Leerling 15', 6.3 , 1, 2, 0, 2, 2, 0, 2, 2, 0, 2, 2, 2, 2, 0, 0, 0, 0, 0, 0, 2, 0, 2, 0, 2, 0, 1, 2, 0, 1, 2, 0, 1, 0, 1, 1, 2, 0, 2, 2, 2, 2, 0, 2);
    this.generateResult(exam, 'Leerling 16', 6.1 , 1, 0, 1, 2, 2, 1, 0, 2, 1, 0, 2, 2, 2, 2, 2, 2, 1, 2, 2, 1, 2, 1, 2, 2, 0, 2, 2, 1, 2, 2, 0, 0, 1, 0, 1, 2, 0, 2, 2, 0, 2, 2, 0);
    this.generateResult(exam, 'Leerling 17', 6.2 , 1, 2, 0, 0, 2, 0, 2, 2, 0, 2, 2, 2, 2, 2, 0, 0, 1, 2, 0, 2, 2, 1, 2, 2, 0, 2, 2, 0, 2, 2, 0, 1, 1, 1, 0, 2, 0, 0, 0, 0, 2, 0, 2);
    this.generateResult(exam, 'Leerling 18', 6.4 , 1, 2, 2, 2, 2, 1, 0, 1, 0, 2, 1, 2, 0, 2, 2, 2, 1, 2, 0, 1, 2, 2, 2, 2, 0, 2, 0, 0, 1, 2, 0, 1, 1, 0, 1, 2, 1, 2, 0, 0, 0, 0, 0);
    this.generateResult(exam, 'Leerling 19', 6.2 , 1, 2, 0, 2, 2, 2, 0, 2, 0, 2, 1, 2, 2, 2, 0, 2, 2, 2, 2, 1, 2, 2, 2, 0, 2, 1, 2, 0, 2, 2, 1, 1, 1, 1, 1, 2, 1, 0, 2, 2, 0, 2, 2);
    this.generateResult(exam, 'Leerling 20', 7.0 , 0, 0, 1, 2, 2, 0, 1, 2, 0, 0, 0, 0, 2, 2, 1, 0, 1, 2, 0, 1, 0, 2, 2, 0, 0, 2, 0, 0, 1, 2, 0, 1, 1, 0, 0, 2, 1, 0, 1, 0, 2, 0, 0);
    this.generateResult(exam, 'Leerling 21', 6.5 , 0, 2, 1, 0, 2, 1, 2, 0, 1, 1, 1, 0, 0, 2, 1, 0, 1, 2, 2, 1, 2, 2, 0, 2, 0, 0, 2, 0, 1, 2, 0, 1, 0, 0, 0, 2, 0, 0, 1, 0, 2, 0, 2);
    this.generateResult(exam, 'Leerling 22', 6.6 , 1, 2, 2, 0, 2, 0, 2, 2, 0, 1, 2, 2, 2, 2, 2, 2, 1, 2, 2, 2, 0, 2, 0, 0, 1, 2, 2, 1, 2, 2, 0, 1, 1, 0, 1, 2, 0, 0, 0, 0, 2, 2, 2);
    this.generateResult(exam, 'Leerling 23', 5.5 , 1, 0, 0, 0, 2, 1, 2, 2, 1, 0, 1, 2, 0, 2, 0, 2, 1, 2, 2, 1, 2, 2, 0, 0, 0, 2, 0, 0, 2, 2, 0, 1, 1, 1, 1, 2, 0, 0, 2, 0, 2, 2, 2);
    this.generateResult(exam, 'Leerling 24', 6.1 , 1, 2, 2, 2, 2, 2, 1, 1, 0, 2, 2, 2, 0, 2, 1, 0, 2, 2, 2, 1, 2, 2, 2, 2, 0, 2, 2, 1, 1, 2, 0, 0, 1, 0, 1, 2, 0, 0, 1, 2, 0, 2, 2);
    this.generateResult(exam, 'Leerling 25', 6.8 , 1, 2, 0, 2, 2, 1, 0, 1, 0, 2, 1, 2, 0, 2, 2, 2, 2, 2, 2, 1, 2, 1, 2, 0, 1, 0, 2, 0, 1, 2, 1, 1, 1, 0, 0, 2, 0, 2, 1, 0, 2, 2, 0);
    this.generateResult(exam, 'Leerling 26', 6.1 , 1, 2, 2, 0, 2, 0, 1, 2, 0, 2, 2, 2, 0, 2, 1, 2, 1, 2, 2, 2, 2, 2, 2, 2, 0, 0, 0, 1, 1, 2, 0, 1, 1, 0, 1, 2, 1, 0, 1, 0, 2, 2, 2);
    this.generateResult(exam, 'Leerling 27', 7.1 , 1, 2, 2, 2, 2, 1, 0, 2, 0, 2, 2, 2, 2, 2, 2, 0, 1, 2, 2, 2, 2, 1, 0, 0, 0, 2, 0, 0, 2, 2, 0, 0, 0, 1, 1, 2, 1, 0, 2, 0, 0, 0, 2);
    this.generateResult(exam, 'Leerling 28', 6.0 , 1, 2, 2, 0, 2, 1, 0, 1, 0, 2, 2, 2, 2, 2, 0, 2, 1, 2, 0, 0, 0, 1, 0, 2, 1, 2, 0, 0, 2, 2, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 2);
    return exam;
  }

  static generateAssignment(exam: Exam, ...questionPoints: number[]) {
    let assignment = new ExamAssignment();
    questionPoints.forEach(qp => {
      let q = new ExamQuestion();
      q.maxPoints = qp;
      assignment.questions.push(q);
    });

    exam.assignments.push(assignment);
  }

  static generateResult(exam: Exam, studentName: string, se: number, ...results: number[]) {
    let result = new ExamResult();
    result.studentName = studentName;
    result.se = se;
    let currIndex = 0;
    exam.assignments.forEach(a => {
      a.questions.forEach(q => {
        result.questionResults.set(q.id, results[currIndex++].toString());
      });
    });

    exam.results.push(result);
  }
}
