import { ExamAssignment } from './exam-assignment';
import { ExamResult } from './exam-result';

export class Exam {
  name: string;
  nTerm: number;
  assignments: ExamAssignment[] = [];
  results: ExamResult[] = [];

  constructor() {
    this.nTerm = 1;
  }

  get totalPoints() {
    return this.assignments.reduce(
      (total, current) => total + current.totalPoints,
      0
    );
  }

  recalculateQuestionNumbers() {
    let currQuestionNumber = 1;
    this.assignments.forEach(a => {
      a.questions.forEach(q => {
        q.questionNumber = currQuestionNumber++;
      });
    });
  }
}
