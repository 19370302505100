import { isNumeric } from 'rxjs/internal/util/isNumeric';

export class ExamResult {
  studentName: string;
  questionResults: Map<string, string> = new Map<string, string>();

  constructor(studentName?: string) {
    if (studentName) {
      this.studentName = studentName;
    }
  }

  se: number;

  get totalPoints() {
    return Array.from(this.questionResults.values()).filter(r => isNumeric(r)).reduce(
      (total, current) => total + parseInt(current, 0),
      0
    );
  }

  calculateCSE(examTotalPoints: number, nTerm: number): number {
    let calculated = (this.totalPoints / examTotalPoints) * 9 + nTerm;
    return Math.min(Math.round(calculated * 10) / 10, 10);
  }

  calculateFinalGrade(examTotalPoints: number, nTerm: number): number {
    let cse = this.calculateCSE(examTotalPoints, nTerm);
    if (!cse || !this.se) {
      return 0;
    }
    return ( cse + this.se) / 2;
  }

  calculateTolerance(examTotalPoints: number, nTerm: number): number {
    return (
      20 *
      (this.calculateFinalGrade(examTotalPoints, nTerm) -
        Math.floor(this.calculateFinalGrade(examTotalPoints, nTerm)) -
        0.5)
    );
  }
}
