import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Exam } from '../classes/exam';

import { ExamResult } from '../classes/exam-result';
import { ExamAssignment } from '../classes/exam-assignment';
import { ExamQuestion } from '../classes/exam-question';
import { ExamGenerator } from '../classes/exam-generator';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-exam-manager',
  templateUrl: './exam-manager.component.html',
  styleUrls: ['./exam-manager.component.less']
})
export class ExamManagerComponent {
  @Input() exam: Exam;
  @Output() examChange = new EventEmitter<Exam>();

  @ViewChild('fileInput') fileInput: ElementRef;

  selectedFile: Blob;

  constructor(private fileSaverService: FileSaverService) { }

  createNewExam() {
    this.exam = ExamGenerator.generateNewExam();
    this.examChange.emit(this.exam);
  }

  generateExistingExam() {
    this.exam = ExamGenerator.generateExistingExam();
  }

  importExam(event) {
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(this.selectedFile, 'UTF-8');
    fileReader.onload = () => {
      let importedExam = JSON.parse(fileReader.result.toString()) as Exam;
      this.processImportedExam(importedExam);
      this.fileInput.nativeElement.value = '';
    };
    fileReader.onerror = error => {
      console.log(error);
    };
  }

  exportToJson() {
    let jsonString = JSON.stringify(this.exam, this.replacer, 2);

    let blob = new Blob([jsonString], {
      type: 'application/json;charset=utf-8'
    });

    let fileName = this.exam.name ? `${this.exam.name}`.replace(' ', '_') : 'export';
    this.fileSaverService.save(blob, `${fileName}.json`);
  }

  private processImportedExam(importedExam: Exam) {
    this.exam.name = importedExam.name;
    this.exam.nTerm = importedExam.nTerm;

    this.exam.assignments = [];
    importedExam.assignments.forEach(a => {
      let assignment = new ExamAssignment();
      assignment.id = a.id;
      assignment.questions = [];
      a.questions.forEach(q => {
        let question = new ExamQuestion();
        question.id = q.id;
        question.questionNumber = q.questionNumber;
        question.maxPoints = q.maxPoints;

        assignment.questions.push(question);
      });

      this.exam.assignments.push(assignment);
    });

    this.exam.results = new Array<ExamResult>();
    importedExam.results.forEach(r => {
      let result = new ExamResult();
      result.studentName = r.studentName;
      result.se = r.se;
      result.questionResults = new Map<string, string>();
      ((r.questionResults as unknown) as Array<[string, string]>).forEach(x => {
        result.questionResults.set(x[0], x[1]);
      });

      this.exam.results.push(result);
    });

    this.examChange.emit(this.exam);
  }

  private replacer(key, value) {
    if (value instanceof Map) {
      // Ensure the Map with question results is serialized correctly.
      return Array.from(value.entries());
    } else {
      return value;
    }
  }
}
