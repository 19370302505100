import { Component, Input, OnInit } from '@angular/core';
import { Exam } from '../classes/exam';
import { ExamAssignment } from '../classes/exam-assignment';
import { ExamQuestion } from '../classes/exam-question';

@Component({
  selector: 'app-exam-definition',
  templateUrl: './exam-definition.component.html',
  styleUrls: ['./exam-definition.component.less']
})
export class ExamDefinitionComponent implements OnInit {

  @Input() exam: Exam;

  constructor() {}

  ngOnInit(): void {}

  addAssignment() {
    let newAssignment = new ExamAssignment();
    this.exam.assignments.push(newAssignment);
    this.addQuestion(newAssignment);
    this.addQuestion(newAssignment);
    this.addQuestion(newAssignment);
    this.addQuestion(newAssignment);
    this.addQuestion(newAssignment);
  }

  addQuestion(assignment: ExamAssignment) {
    assignment.questions.push(new ExamQuestion());
    this.exam.recalculateQuestionNumbers();
  }

  removeLastQuestion(assignment: ExamAssignment) {
    assignment.questions.pop();
    this.exam.recalculateQuestionNumbers();
  }

  removeLastAssignment() {
    this.exam.assignments.pop();
  }
}
