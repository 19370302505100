import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { isNumeric } from 'rxjs/internal/util/isNumeric';
import { Exam } from '../classes/exam';
import { ExamResult } from '../classes/exam-result';

@Component({
  selector: 'app-exam-results',
  templateUrl: './exam-results.component.html',
  styleUrls: ['./exam-results.component.less']
})
export class ExamResultsComponent implements OnInit, OnChanges {
  @Input() exam: Exam;
  @ViewChild(MatTable) table: MatTable<any>; // initialize
  @ViewChild('resultsTable', { read: ElementRef }) resultsTable;

  isModeSummary: boolean;
  customNTerm: number;
  dataSource = new MatTableDataSource<ExamResult>();

  get examResults() {
    return [...this.exam.results];
  }

  get assignmentIds() {
    return this.exam.assignments.map(a => a.id);
  }

  get questionIds(): string[] {
    let x = [];
    this.exam.assignments.forEach(a => {
      x.push(...a.questions.map(q => q.id));
    });

    return x;
  }

  get columns() {
    let columns = [
      'buttons',
      'studentName',
      'info',
      'fill',
      'totalPoints',
      'cse',
      'se',
      'finalGrade',
      'finalGradeRounded',
      'diff',
      'tolerance',
      'customNTermCSE',
      'customNTermFinal'
    ];
    if (!this.isModeSummary) {
      columns.splice(3, 0, ...this.questionIds);
    } else {
      columns.splice(3, 0, ...this.assignmentIds);

    }

    return columns;
  }

  constructor(private changeDetectorRefs: ChangeDetectorRef) {
    changeDetectorRefs.detach();

    setInterval(() => {
      changeDetectorRefs.detectChanges();
    }, 250);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refreshTable();
  }

  ngOnInit(): void {
    this.dataSource.data = this.exam.results;
    this.customNTerm = this.exam.nTerm;
  }

  changeMode() {
    this.isModeSummary = !this.isModeSummary;
    this.table.renderRows();
  }

  public refreshTable() {
    this.dataSource.data = this.exam.results;
    this.customNTerm = this.exam.nTerm;
    this.changeDetectorRefs.detectChanges();
  }

  addResult() {
    let newResult = new ExamResult();
    this.exam.results.push(newResult);
    this.dataSource = new MatTableDataSource(this.exam.results);
  }

  removeResult(result: ExamResult) {
    let index = this.exam.results.indexOf(result);
    this.exam.results.splice(index, 1);
    this.dataSource = new MatTableDataSource(this.exam.results);
  }

  getResult(result, questionId) {
    return result.questionResults.get(questionId);
  }

  setResult(result, question, value) {
    result.questionResults.set(question.id, value);
  }

  keyPress(index, questionNumber, isLastResult, isLastAssignment, isLastQuestion) {
    let nextResult;

    if (isLastResult && isLastAssignment && isLastQuestion) {
      return;
    }

    if (!isLastResult) {
      nextResult = this.resultsTable.nativeElement.querySelector(
        `#answer_${index + 1}_${questionNumber}`
      );
    } else {
      nextResult = this.resultsTable.nativeElement.querySelector(
        `#answer_${0}_${questionNumber + 1}`
      );
    }
    setTimeout(() => (nextResult as HTMLInputElement).focus(), 1);
  }

  getAssignmentTotal(
    questionResults: Map<string, string>,
    assignmentId: string
  ) {
    let assignment = this.exam.assignments.find(a => a.id === assignmentId);

    if (!assignment) {
      return 0;
    }

    let total = 0;

    assignment.questions.forEach(q => {
      let result = questionResults.get(q.id);
      if (result && isNumeric(result)) {
        total += parseFloat(result);
      }
    });

    return total;
  }

  getAssignmentAverage(assignmentId) {
    let assignment = this.exam.assignments.find(a => a.id === assignmentId);

    if (!assignment) {
      return 0;
    }

    let total = 0;

    assignment.questions.forEach(q => {
      this.exam.results.forEach(r => {
        let result = r.questionResults.get(q.id);
        if (result && isNumeric(result)) {
          total += parseFloat(result);
        }
      });
    });

    return total / this.examResults.length;
  }

  getQuestionAverage(questionId) {
    let total = 0;

    this.exam.results.forEach(r => {
      let result = r.questionResults.get(questionId);
      if (result && isNumeric(result)) {
          total += parseFloat(result);
        }
    });

    return total / this.examResults.length;
  }

  getQuestionEmptyTotal(questionId) {
    let total = 0;

    this.exam.results.forEach(r => {
      let result = r.questionResults.get(questionId);
      if (result === 'n') {
        total++;
      }
    });

    return total;
  }

  getTotalPointsAverage() {
    let total = 0;
    this.examResults.forEach(r => {
      total += r.totalPoints;
    });

    return total / this.examResults.length;
  }

  getCSEAverage(nTerm: number) {
    let total = 0;
    this.examResults.forEach(r => {
      total += r.calculateCSE(this.exam.totalPoints, nTerm);
    });

    return total / this.examResults.length;
  }

  getSEAverage() {
    let total = 0;
    this.examResults.forEach(r => {
      total += r.se;
    });

    return total / this.examResults.length;
  }

  getFinalGradeAverage(nTerm: number) {
    let total = 0;
    this.examResults.forEach(r => {
      total += r.calculateFinalGrade(this.exam.totalPoints, nTerm);
    });

    return total / this.examResults.length;
  }

  changeCustomNTerm(incrementation: number) {
    if (incrementation > 0) {
      this.customNTerm = Math.min(
        (this.customNTerm * 10 + incrementation * 10) / 10,
        2
      );
    } else {
      this.customNTerm = Math.max(
        (this.customNTerm * 10 + incrementation * 10) / 10,
        0
      );
    }
  }
}
