import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-result-indicator',
  templateUrl: './result-indicator.component.html'
})
export class ResultIndicatorComponent {
  @Input() value: string;
  @Input() delimiter: string;
  @Input() negative: number;
  @Input() positive: number;

  getClass() {
    let parsed = parseFloat(this.value);
    if (parsed < this.negative) {
      return 'negative';
    } else if (parsed > this.positive) {
      return 'positive';
    }

    return null;
  }

  getValue() {
    let value = this.value;
    if (this.delimiter) {
      value = value.replace('.', this.delimiter);
      if (value.indexOf(',') < 0) {
        // Add trailing zero
        value += ',0';
      }
    }
    return value;
  }
}
