import { v4 as uuidv4 } from 'uuid';
import { ExamQuestion } from './exam-question';

export class ExamAssignment {
  id: string;
  questions: ExamQuestion[] = [];

  get totalPoints() {
    return this.questions.reduce(
      (total, current) => total + current.maxPoints,
      0
    );
  }

  constructor() {
    this.id = uuidv4();
  }
}
