import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { isNumeric } from 'rxjs/internal-compatibility';

@Directive({
   selector: '[appEmptyScoreInput][ngModel]'
})
export class EmptyScoreInputDirective {
   @Input() min: number;
   @Input() max: number;
   private specialKeys: Array<string> = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'Delete',
      'Del',
      'Ctrl',
      'ArrowLeft',
      'ArrowRight',
      'Left',
      'Right'
   ];

   constructor(
      private el: ElementRef<HTMLInputElement>
   ) { }

   get valueIsSelected(): boolean {
      return this.el.nativeElement.selectionStart === 0 && this.el.nativeElement.selectionEnd === this.el.nativeElement.value.length;
   }

   @HostListener('keydown', ['$event'])
   keyEvent(event: KeyboardEvent) {

      let valid = false;
      if (this.specialKeys.indexOf(event.key) !== -1) {
         valid = true;
      }

      if (!this.el.nativeElement.value || this.valueIsSelected) {
         if (event.key === 'n') {
            valid = true;
         } else {
            if (isNumeric(event.key)) {
               let numValue = parseFloat(event.key);
               if (numValue >= this.min && numValue <= this.max) {
                  valid = true;
               }
            }
         }
      } else {
         if (isNumeric(this.el.nativeElement.value + event.key)) {
            let numValue = parseFloat(this.el.nativeElement.value + event.key);
            if (numValue >= this.min && numValue <= this.max) {
               valid = true;
            }
         }
      }

      if (valid) {
         return;
      } else {
         event.preventDefault();
      }

      // console.log("Key", event.key);
      // console.log("ngModel", this.ngModel.value);
      // if (!this.el.nativeElement.value && event.key == "n") {
      //   return;
      // }

      // if (
      //   isNumeric(this.el.nativeElement.value + event.key) ||
      //   (isNumeric(this.el.nativeElement.value) &&
      //     (event.key == "ArrowUp" || event.key == "ArrowDown"))
      // ) {
      //   let numValue: number;
      //   if (event.key == "ArrowUp") {
      //     numValue = parseInt(this.el.nativeElement.value) + 1;
      //   } else if (event.key == "ArrowDown") {
      //     numValue = parseInt(this.el.nativeElement.value) - 1;
      //   } else {
      //     numValue = parseInt(this.el.nativeElement.value + event.key);
      //   }

      //   console.log(numValue);

      //   if (numValue < this.min) {
      //     event.preventDefault();
      //     return;
      //   }

      //   if (this.max && numValue > this.max) {
      //     event.preventDefault();
      //     return;
      //   }

      //   if (event.key == "ArrowUp" || event.key == "ArrowDown") {
      //     console.log("up or down");
      //     if (!numValue) {
      //       console.log("!numValue");
      //       numValue = 0;
      //     }
      //     this.el.nativeElement.value = numValue.toString();
      //   }
      // } else {
      //   event.preventDefault();
      // }
   }
}
