import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'grade' })
export class GradePipe implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return null;
    }

    let inputString = input.toString();

    let result = inputString.replace('.', ',');
    if (result.indexOf(',') < 0) {
      // Add trailing zero
      result += ',0';
    }

    return result;
  }
}
