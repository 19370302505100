<button mat-stroked-button color="primary" class="mr-1" (click)="createNewExam()">
  <mat-icon>add_box</mat-icon>
  <span>Nieuw examen</span>
</button>
<input style="display: none" type="file" (change)="importExam($event)" #fileInput>
<button mat-stroked-button color="primary" class="mr-1" (click)="fileInput.click()">
  <mat-icon>upload</mat-icon>
  <span>Data inladen</span>
</button>
<button mat-stroked-button color="primary" (click)="exportToJson()">
  <mat-icon>download</mat-icon>
  <span>Data opslaan</span>
</button>
<!-- <button  mat-stroked-button color="primary" class="mr-1" (click)="generateExistingExam()">Bestaand examen</button> -->