import { Component, ViewChild } from '@angular/core';
import { version } from '../../package.json';
import { Exam } from './classes/exam';
import { ExamGenerator } from './classes/exam-generator';

import { ExamManagerComponent } from './exam-manager/exam-manager.component';
import { ExamResultsComponent } from './exam-results/exam-results.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  exam: Exam;
  Math = Math;
  appVersion = version;
  loading: boolean;
  showJson: boolean;

  @ViewChild('examResults') examResults: ExamResultsComponent;
  @ViewChild('examManager') examManager: ExamManagerComponent;

  constructor() {
    this.exam = ExamGenerator.generateNewExam();
  }

  onExamChanged(event: Exam) {
    this.exam = event;
    this.examResults.refreshTable();
  }
}
