import { v4 as uuidv4 } from 'uuid';

export class ExamQuestion {
  constructor() {
    this.id = uuidv4();
    this.maxPoints = 1;
  }

  id: string;
  questionNumber: number;
  maxPoints: number;
}
